import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Form from './screens/Form';
import Result from './screens/Result';
import { AppBar, Toolbar, Button, Grid } from '@mui/material';



function App() {
  let currentYear = new Date().getFullYear();

  return (
    <BrowserRouter>
      {/* <AppBar
      style={{background:'white'}}
      >
        <Toolbar style={{alignSelf:'center'}}>
          <Button href="https://consultmidia.it/" >
            <img src="/logo.png" alt="Logo" />
            </Button>
        </Toolbar>
      </AppBar>
      <div style={{ paddingBottom: '120px', background:'#f3f4f6' }}></div> */}
      <Routes>
        <Route index element={<Form />} />
        <Route path='/result' element={<Result />} />
      </Routes>
      <footer style={{ paddingTop: '20px', background:'#f3f4f6' }}>
        <Grid container style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <h6>
            {`© ${currentYear} , copyright Consult Mídia`}
          </h6>
        </Grid>
      </footer>
    </BrowserRouter>
  );
}

export default App;
