



export function getResultIntro(nivel) {
    const nivelTextos = {
        1: "Nível 1: Inicial. Esse nível indica que a empresa está no início de sua jornada digital, com poucas ou nenhumas práticas e tecnologias digitais implementadas.",
        2: "Nível 2: Conectado. Esse nível indica que a empresa começou a adotar algumas práticas e tecnologias digitais, mas ainda está nos estágios iniciais de sua transformação digital.",
        3: "Nível 3: Automação Básica. Esse nível indica que a empresa tem algumas práticas de automação implementadas e está começando a usar dados para tomar decisões.",
        4: "Nível 4: Digitalização. Esse nível indica que a empresa tem um bom grau de digitalização e integração de processos, com o uso regular de ferramentas de BI.",
        5: "Nível 5: Dados e Análises. Esse nível indica que a empresa faz uso avançado de análise de dados e começou a integrar IA em seus processos.",
        6: "Nível 6: Integração de IA. Esse nível indica que a empresa faz uso extensivo de IA e automação avançada em suas operações.",
        7: "Nível 7: Transformação Digital Total. Esse nível indica que a empresa integrou completamente tecnologias emergentes, com um alto nível de automação e digitalização."
    };

    return nivelTextos[nivel];

}


export function getPositiveText(category) {


    const pontosPositivos = {
        "Estratégia e Visão Digital": [
            "Está começando a adotar práticas digitais básicas, o que demonstra uma conscientização inicial sobre a importância da digitalização.",
            "A liderança da empresa reconhece a necessidade de transformação digital, o que é um passo crucial para futuras iniciativas de digitalização."
        ],
        "Cultura e Organização": [
            "Implementou algumas práticas e tecnologias digitais, iniciando sua jornada rumo à transformação digital.",
            "Houve uma melhoria na comunicação digital interna, facilitando a colaboração e o fluxo de informações dentro da empresa."
        ],
        "Tecnologia e Infraestrutura": [
            "Implementou práticas de automação básicas, aumentando a eficiência e reduzindo erros em processos operacionais.",
            "Começou a usar dados para tomar decisões, melhorando a precisão e a base analítica das estratégias empresariais."
        ],
        "Processos e Operações": [
            "Alcançou um bom grau de digitalização e integração de processos, facilitando a troca de informações e a coordenação entre departamentos.",
            "Usa regularmente ferramentas de Business Intelligence (BI) para gerar insights e apoiar a tomada de decisões estratégicas."
        ],
        "Dados e Análise": [
            "Faz uso avançado de análise de dados, permitindo uma compreensão profunda das operações e do mercado.",
            "Começou a integrar Inteligência Artificial (IA) em seus processos, otimizando operações e melhorando a eficiência."
        ],
        "Experiência do Cliente": [
            "Faz uso extensivo de IA em várias operações, automatizando tarefas complexas e melhorando a precisão das análises.",
            "Implementou automação avançada em diversas operações, aumentando a eficiência e reduzindo significativamente o tempo de execução dos processos."
        ],
        "Crescimento e Inovação": [
            "Integrou completamente tecnologias emergentes, garantindo que todas as áreas operacionais estejam otimizadas com as soluções mais avançadas disponíveis.",
            "Atingiu um alto nível de automação e digitalização, operando de forma altamente eficiente e inovadora."
        ]
    };

    return pontosPositivos[category];

}


export function getTextMelhoria(category) {

    const areasMelhoria = {
        "Estratégia e Visão Digital": [
            "Necessidade de adotar práticas e tecnologias digitais para melhor alinhar os objetivos estratégicos da empresa com a transformação digital.",
            "Falta de uma visão estratégica clara que oriente efetivamente a transformação digital, dificultando a definição de metas e ações específicas.",
        ],
        "Cultura e Organização": [
            "Há uma necessidade de maior integração digital na cultura organizacional, incentivando uma mentalidade de inovação e adaptabilidade às novas tecnologias.",
            "Os colaboradores carecem de capacitação suficiente em tecnologias digitais, o que limita a maximização do potencial das ferramentas disponíveis.",
        ],
        "Tecnologia e Infraestrutura": [
            "É necessário aumentar a automação de processos para melhorar a eficiência operacional e reduzir possíveis erros.",
            "O uso limitado de dados para tomada de decisões impede uma análise mais profunda e abrangente, prejudicando a base analítica das estratégias empresariais.",
        ],
        "Processos e Operações": [
            "Existe uma necessidade de expandir a digitalização e integração de processos para facilitar a colaboração entre diferentes departamentos e melhorar a eficiência operacional.",
            "O uso limitado de análise de dados avançada impede a empresa de obter insights mais profundos e preditivos para orientar decisões estratégicas.",
        ],
        "Dados e Análise": [
            "É crucial aumentar a integração de Inteligência Artificial (IA) nos processos para otimizar operações e melhorar a eficiência.",
            "A falta de automação em algumas áreas específicas ainda impede a empresa de alcançar seu potencial máximo em termos de eficiência operacional e inovação.",
        ],
        "Experiência do Cliente": [
            "Há uma necessidade urgente de integrar completamente a Inteligência Artificial (IA) em todas as operações para automatizar tarefas complexas e melhorar a personalização das experiências dos clientes.",
            "Existem áreas específicas que ainda não estão totalmente automatizadas, o que pode afetar a consistência e eficiência na entrega de serviços aos clientes.",
        ],
        "Crescimento e Inovação": [
            "É essencial continuar adaptando-se às novas tecnologias emergentes para manter a competitividade e garantir a eficiência operacional.",
            "Manter um foco contínuo em inovação e melhoria é fundamental para impulsionar o crescimento sustentável da empresa e atender às demandas do mercado.",
        ]
    };

    return areasMelhoria[category];

}


export function getTextConclusao(nivel) {

    const conclusoesPorNivel = {
        1: "A empresa está nos estágios iniciais de sua jornada de digitalização. Há um longo caminho a percorrer para integrar completamente as tecnologias digitais em suas operações.",
        2: "A empresa está no nível de digitalização conectado. Embora haja progresso na integração de tecnologias digitais, ainda existem áreas significativas que precisam ser melhoradas para alcançar um nível mais avançado.",
        3: "A empresa alcançou o nível de automação básica em suas operações. É um passo positivo em direção à digitalização, mas há espaço para expandir e aprimorar ainda mais as práticas digitais.",
        4: "A empresa atingiu o nível de digitalização. Isso indica que já implementou uma série de práticas e tecnologias digitais em suas operações. No entanto, ainda há áreas que podem ser aprimoradas para otimizar ainda mais suas capacidades digitais.",
        5: "A empresa está no nível de dados e análises avançadas. Com o uso intensivo de análise de dados e integração de IA, a empresa está bem posicionada para tomar decisões estratégicas baseadas em insights precisos e preditivos.",
        6: "A empresa alcançou um alto nível de integração de IA em suas operações. Isso possibilita uma automação avançada e uma eficiência operacional significativamente melhorada, colocando-a na vanguarda da transformação digital.",
        7: "A empresa alcançou a transformação digital total. Integrando completamente tecnologias emergentes, operando de maneira altamente eficiente e inovadora, a empresa está preparada para enfrentar os desafios do mercado atual."
    };

    return conclusoesPorNivel[nivel];

}