import React, { forwardRef } from 'react';
import TextField from '@mui/material/TextField';

const TelephoneMasked = forwardRef(({ value, onChange, label, type, ...other }, ref) => {
    const handleChange = (event) => {
        let { value } = event.target;
        value = value.replace(/\D/g, ''); // Remove non-numeric characters
        value = value.replace(/(\d{2})(\d)/, '($1) $2'); // Add parentheses around area code
        if (type === 'CELULAR') {
            value = value.replace(/(\d{5})(\d)/, '$1-$2'); // Add hyphen in phone number
            if (value.length > 15) {
                value = value.slice(0, 15); // Limit length to 15 characters
            }
        } else {
            value = value.replace(/(\d{4})(\d)/, '$1-$2'); // Add hyphen in phone number
            if (value.length > 14) {
                value = value.slice(0, 14); // Limit length to 15 characters
            }
        }
        onChange(value);
    };

    return (
        <TextField
            ref={ref}
            value={value}
            onChange={handleChange}
            label={label}
            {...other}
        />
    );
});

export default TelephoneMasked;